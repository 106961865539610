import { defineStore } from "pinia";
import axios from "~/plugins/axios";
import Cookies from 'js-cookie'

const $axios = axios().provide.axios;

export const useUserStore = defineStore('user', {
    state: () => ({
        id: '',
        name: '',
        email: '',
        api_token: '',
        balance: '',
        document: '',
        phone: '',
        isLoggedIn: false,
        referrer_id: '',
        commission: ''
    }),
    
    actions: {
        async login(email, password) {
            await $axios.post('/api/auth/login', {
              email: email,
              password: password,
              login: email
            }).then((result) => {
                let data = this.parseJwt(result.data.access_token)

                const expirationInMilliseconds = result.data.expires_in * 1000
                const expirationDate = new Date(Date.now() + expirationInMilliseconds)
        
                Cookies.set('token', result.data.access_token, { expires:  expirationDate})
                Cookies.set('user', JSON.stringify(data), { expires:  expirationDate})

                this.$state.balance = data.balance
                this.$state.api_token = result.data.access_token
                this.$state.id = data.user_id
                this.$state.name = data.name
                this.$state.document = data.document
                this.$state.phone = data.phone
                this.$state.email = data.email
                this.$state.isLoggedIn = true;
            });
        },
        
        async register(name, email, password, confirmPassword) {
            await $axios.post('/api/register', {
              name: name,
              email: email,
              password: password,
              password_confirmation: confirmPassword
            })
        },

        async getUser() {

            let res = Cookies.get('user')
            if(res){
                res = JSON.parse(res)
                this.$state.id = res.user_id
                this.$state.isLoggedIn = true
                this.$state.document = res.document

                $axios.post('/api/auth/me').then((result) => {
                    this.$state.balance = result.data.balance
                    this.$state.name = result.data.name
                    
                    this.$state.phone = result.data.phone
                    this.$state.email = result.data.email
                    this.$state.referrer_id = result.data.referrer_id
                    this.$state.commission = result.data.commission

                });
            }
        },
        base64urlToBase64(base64url) {
            base64url = base64url.replace(/-/g, '+').replace(/_/g, '/');
        
            switch (base64url.length % 4) {
                case 2:
                    base64url += '==';
                    break;
                case 3:
                    base64url += '=';
                    break;
            }
        
            return base64url;
        },
        parseJwt (token) {
            try {
                return JSON.parse(atob(this.base64urlToBase64(token.split(".")[1])));
            } catch (e) {
                return null;
            }
        },
      
        async logout() {
            this.resetState()
        },

        resetState() {      
            Cookies.remove('token')
            Cookies.remove('user')
            this.$state.id = ''
            this.$state.name = ''
            this.$state.email = ''
            this.$state.api_token = ''
            this.$state.isLoggedIn = false

            return navigateTo('/')
        },
    },
    persist: true,
})